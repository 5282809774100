import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import MainTitle from "../../components/titles/MainTitle"
import IconTree from "../../images/tree.svg"
import Section from "../../components/section/Section"
import ButtonLink from "../../components/button/ButtonLink"
import css from "./Feminism.module.css"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.feminism}
      slug={url}
      title="Feminismo"
      theme="feminism"
    >
      <MainTitle
        title="Feminismo"
        description="Con el movimiento de mujeres, en la defensa de nuestros derechos"
        icon={<IconTree />}
        theme="feminism"
      />

      <Section className={css.presentation}>
        <Markdown>
          {data.feminism.descripcion.childMarkdownRemark.html}
        </Markdown>
      </Section>

      <Section className={css.volunteering}>
        <div className={css.volunteeringPresentation}>
          <h1>Voluntariado</h1>
          <div>
            <Markdown className={css.body}>
              {data.volunteering.convocatoria.childMarkdownRemark.html}
            </Markdown>
            <ButtonLink
              icon="assignment"
              url={data.volunteering.urlFormulario}
              activeClassName={css.active}
              external
            >
              Postular
            </ButtonLink>
          </div>
          <img
            className="mb-64"
            src={data.volunteering.afiche.file.url}
            alt="poster"
          />
        </div>
      </Section>

      <Section className={css.volunteeringDescripcion}>
        <div className={css.formacion}>
          <h2>Formación para promover derechos</h2>
          <Markdown>
            {data.volunteering.formacion.childMarkdownRemark.html}
          </Markdown>
        </div>
        <div className={css.activismo}>
          <h2>Activismo feminista</h2>
          <Markdown>
            {data.volunteering.activismo.childMarkdownRemark.html}
          </Markdown>
        </div>
        <div className={css.empoderamiento}>
          <h2>Empoderamiento desde el arte y la música</h2>
          <Markdown>
            {data.volunteering.empoderamiento.childMarkdownRemark.html}
          </Markdown>
        </div>
      </Section>

      <Section className={css.volunteeringText}>
        <Markdown>{data.volunteering.cuerpo.childMarkdownRemark.html}</Markdown>
      </Section>

      <Section className={css.calendar}>
        <h1>Calendario Feminista</h1>
        <div className={css.calendarContainer}>
          {data.calendar.map((day, i) => (
            <Link key={i} to={`/${day.slug}`} className={css.calendarItem}>
              <div className={css.top}>
                <div className={css.day}>{day.dia}</div>
                <div className={css.month}>{day.mes}</div>
              </div>
              <h2>{day.nombre}</h2>
              <Markdown>{day.presentacion.childMarkdownRemark.html}</Markdown>
            </Link>
          ))}
        </div>
      </Section>

      {/* <section className="articles-related">
        <div className="container">
          <h2>Últimas actividades de voluntariado</h2>
          <Grid>
            {data.articles.slice(0, 4).map((item, i) => (
              <Card url={`/${item.slug}`} key={i}>
                <img src={item.portada?.file.url} alt="img"></img>
                <h3>{item.titulo}</h3>
                <Date date={item.fecha} />
                <p>{item.cuerpo.childMarkdownRemark.excerpt}</p>
              </Card>
            ))}
          </Grid>
        </div>
      </section>*/}

      <Section className={css.campaigns}>
        <h1>Campañas</h1>
        <div className={css.campaignsContainer}>
          {data.campaigns.map((campaign, i) => (
            <Link key={i} to={`/${campaign.slug}`} className={css.campaignItem}>
              <div>
                <img src={campaign.portada?.file.url} alt="img"></img>
                <h2>{campaign.nombre}</h2>
              </div>
            </Link>
          ))}
        </div>
      </Section>
    </Layout>
  )
}
